import { createApp } from 'vue'

//@ 引入vant弹出框
import { Dialog, Toast } from 'vant';
import 'vant/es/dialog/style';
import 'vant/es/toast/style';

// @ --- 引入组件
import App from '@/web/shouye/shouye.vue'

import guivue from '@/gui/vue/guivue.js';



const app = createApp(App)

//# 引入路由文件
import router from '@/router.js'


// Dialog 注册vant的提示框
app.use(Dialog)
// Toast 注册vant的提示框
app.use(Toast)
app.use(router)
// 挂载guisdk
app.config.globalProperties.guivue = guivue
app.mount('#app')




//初始化Vue

// window.Apps = createApp(App)
//     .use(Dialog)
//     .use(Toast)
// // 挂载guisdk
// app.config.globalProperties.guivue = guivue
//     .mount('#app')
