import "core-js/modules/es.array.push.js";
//# --- 初始化变量 
var object = {
  methods: {},
  name: 'App',
  mounted() {
    this.$router.push({
      //需要跳转的组件路径，需要在router.js配置
      path: '/index'
    });
  }
};

//# ---- 返回变量 
export default object;